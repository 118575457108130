export enum DateTimeFormat {
  be_date = 'YYYY-MM-DD',
  be_date_2 = 'YYYY/MM/DD',
  be_full_date_time = 'YYYY-MM-DD HH:mm:ss',
  fe_date = 'DD-MM-YYYY',
  fe_date_2 = 'DD/MM/YYYY',
  fe_full_date_time = 'DD/MM/YYYY | HH:mm:ss',
  full_time = 'HH:mm:ss',
  month = 'MM',
  month_year = 'MM/YYYY',
  time = 'HH:mm',
}
