// AccessDenied.tsx
import React from 'react';

const AccessDenied = () => {
  //   const navigate = useNavigate();

  //   const handleGoBack = () => {
  //     navigate(-1);
  //   };

  return (
    <div className="d-flex justify-content-center mt-10 vh-100">
      <div className="text-center">
        <p>Chức năng bị hạn chế.</p>
      </div>
    </div>
  );
};

export { AccessDenied };
